import { Button } from "@clockwise/design-system";
import { bg_brand_emphasis, bg_neutral_inset } from "@clockwise/design-system/tokens";
import { Check, Close } from "@clockwise/icons";
import { appPaths } from "@clockwise/web-commons/src/constants/route.constants";
import { useUpdateActiveEvent } from "@clockwise/web-commons/src/util/ActiveEventContext";
import { TrackingEvents, useTracking } from "@clockwise/web-commons/src/util/analytics.util";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useNUXChecklist } from "../../../../hooks/useNUXChecklist/useNUXChecklist";
import { NUXChecklistState } from "../../../../hooks/useNUXChecklist/useNUXChecklist.util";
import { OnboardingFlowState } from "../../../../hooks/useOnboarding/useOnboarding";
import { useUpdateActiveEventDiff } from "../../../chat-plus-calendar/util/ActiveDiffContext";
import { isItemComplete } from "../../../nux-checklist/utils/NUXChecklist.util";
import {
  ChecklistCopy as CHECKLIST_COPY,
  NUXChecklistVariant,
} from "../../../nux-checklist/utils/NUXChecklistCopy";
import { SegmentedBar } from "../../../segmented-bar";
import { useAIMessageContext } from "../hooks/AIMessageContext";
import { useUpdateSuggestedPrompt } from "../hooks/SuggestedPromptContext";
import { AIDefragCountdown } from "./AIDefragCountdown";

const CheckListItem = ({
  variant,
  isItemComplete,
  onClick,
}: {
  variant: NUXChecklistVariant;
  isItemComplete: boolean;
  onClick: () => void;
}) => {
  return (
    <div className="cw-font-medium cw-body-sm cw-w-full">
      {isItemComplete ? (
        <div className="cw-flex cw-items-center cw-gap-1 cw-text-subtle">
          <Check className="cw-w-5 cw-h-5" />
          <span className="cw-line-through">{CHECKLIST_COPY[variant].title}</span>
        </div>
      ) : (
        <div
          onClick={onClick}
          className="cw-bg-neutral-inset hover:cw-bg-neutral-inset-hover cw-cursor-pointer cw-rounded-lg cw-underline cw-decoration-dashed cw-decoration-subtle cw-underline-offset-4 cw-py-1.5 cw-px-2"
        >
          {CHECKLIST_COPY[variant].title}
        </div>
      )}
    </div>
  );
};

const list = [
  NUXChecklistVariant.ConnectCalendar,
  NUXChecklistVariant.Holds,
  NUXChecklistVariant.FlexMeeting,
  NUXChecklistVariant.IdealDay,
  NUXChecklistVariant.ScheduledMeeting,
];

export const TryClockwiseChecklist = () => {
  const [hidden, setHidden] = React.useState(false);
  const track = useTracking();
  const { shouldExcludeAi } = useAIMessageContext();
  const updatePromptText = useUpdateSuggestedPrompt();
  const setActiveEvent = useUpdateActiveEvent();
  const setActiveDiff = useUpdateActiveEventDiff();
  const navigate = useNavigate();
  const {
    flexMeetingCurrentState,
    holdsCurrentState,
    preferencesCurrentState,
    showFlexMeetingStep,
    scheduleMeetingCurrentState,
    aiChecklistClosedCurrentState,
    finishStep,
    loading,
  } = useNUXChecklist();

  const hasUserClosedChecklist =
    !aiChecklistClosedCurrentState || isItemComplete(aiChecklistClosedCurrentState);

  const getSegments = (complete: number, total: number) => {
    return [
      { color: bg_brand_emphasis, value: complete },
      { color: bg_neutral_inset, value: total - complete },
    ];
  };

  const onCloseChecklist = () => {
    setHidden(true); // faster closing
    // Check the AIChecklistClosed step as done, this is what determines if the checklist is shown
    finishStep(OnboardingFlowState.AIChecklistClosed);
  };

  const handleFindTime = () => {
    if (shouldExcludeAi) return;
    const text = "Schedule @";
    updatePromptText(text);
    setActiveEvent(null);
    setActiveDiff(null);
    track(TrackingEvents.CHAT.NUX.SIDEBAR_SCHEDULE_MEETING_CHECKLIST_CLICKED, { text });
  };

  const onMaybeCompleteStep = (variant: NUXChecklistVariant) => {
    switch (variant) {
      case NUXChecklistVariant.FlexMeeting:
        navigate(appPaths.flexibleMeetings);
        break;
      case NUXChecklistVariant.Holds:
        const goToHolds = () => navigate(appPaths.flexibleHolds);
        finishStep(OnboardingFlowState.ChecklistHolds, goToHolds);
        break;
      case NUXChecklistVariant.IdealDay:
        const goToIdealDay = () => navigate(appPaths.accountPreferences);
        finishStep(OnboardingFlowState.ChecklistPreferences, goToIdealDay);
        break;
      case NUXChecklistVariant.ScheduledMeeting:
        handleFindTime();
        break;
      default:
        break;
    }
  };

  const stateForVariant = (variant: NUXChecklistVariant) => {
    switch (variant) {
      case NUXChecklistVariant.FlexMeeting:
        return flexMeetingCurrentState;
      case NUXChecklistVariant.Holds:
        return holdsCurrentState;
      case NUXChecklistVariant.IdealDay:
        return preferencesCurrentState;
      case NUXChecklistVariant.ScheduledMeeting:
        return scheduleMeetingCurrentState;
      default:
        return NUXChecklistState.Unstarted;
    }
  };

  const statuses = list.map((variant) => {
    const currentStateForStep = stateForVariant(variant);
    const isComplete = isItemComplete(currentStateForStep);
    let showStep = true;
    if (variant === NUXChecklistVariant.FlexMeeting && !showFlexMeetingStep) {
      showStep = false;
    } else if (variant === NUXChecklistVariant.Holds && showFlexMeetingStep) {
      showStep = false;
    }
    return {
      variant,
      isComplete: variant === NUXChecklistVariant.ConnectCalendar || isComplete,
      showStep,
    };
  });
  const completedSteps = statuses.filter((status) => status.isComplete && status.showStep).length;
  const totalSteps = statuses.filter((status) => status.showStep).length;

  if (hidden || loading || hasUserClosedChecklist) {
    return null;
  }

  return (
    <div className="cw-flex cw-flex-col cw-w-full cw-rounded-2xl cw-py-2 cw-px-3 cw-border cw-border-solid cw-border-muted cw-gap-2.5">
      <div className="cw-flex cw-justify-between cw-items-center cw-gap-4">
        <div className="cw-body-base cw-text-12 cw-text-subtle cw-font-semibold">Try Clockwise</div>
        <div className="cw-relative cw-flex-1 cw-h-2">
          <SegmentedBar segments={getSegments(completedSteps, totalSteps)} animateIn={false} />
        </div>
        <Button onClick={onCloseChecklist} variant="text" size="mini" startIcon={Close} />
      </div>
      {statuses.map(({ variant, isComplete, showStep }) => {
        if (!showStep) return null;
        return (
          <CheckListItem
            onClick={() => {
              onMaybeCompleteStep(variant);
            }}
            key={variant}
            variant={variant}
            isItemComplete={isComplete}
          />
        );
      })}
      <AIDefragCountdown />
    </div>
  );
};
