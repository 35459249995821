import { Button } from "@clockwise/design-system";
import { SvgIcon } from "@material-ui/core";
import classNames from "classnames";
import React from "react";

export type ButtonProps = {
  text: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  icon?: typeof SvgIcon;
};
export const CalloutCard = ({
  onClick,
  color,
  title,
  subText,
  buttons,
  showHoverState = true,
}: {
  subText: string;
  onClick: () => void;
  color: string | null;
  title?: string;
  buttons: ButtonProps[];
  showHoverState?: boolean;
}) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "cw-flex cw-relative cw-bg-neutral-inset cw-w-full cw-p-3 cw-gap-3 cw-rounded-lg cw-items-center",
        { "hover:cw-bg-neutral-inset-hover cw-cursor-pointer": showHoverState },
      )}
    >
      {color && (
        <>
          {/* For spacing of absolute bar below */}
          <div className="cw-w-[5px]" />
          {/* We are using absolute positioning here, so that the bar is aware of the parents height without the parent having a set height */}
          <div
            style={{ background: color, height: "calc(100% - 24px)" }}
            className="cw-w-[5px] cw-rounded-full cw-top-3 cw-absolute"
          />
        </>
      )}

      <div className="cw-flex-1 cw-flex cw-flex-col cw-gap-1">
        <div>
          {title && <div className="cw-font-semibold cw-body-sm">{title}</div>}
          <div className="cw-text-muted cw-body-sm cw-text-12">{subText}</div>
        </div>
        <div className="cw-flex cw-items-center cw-gap-1">
          {buttons.map((button) => (
            <Button
              key={button.text}
              onClick={button.onClick}
              size="mini"
              variant="outlined"
              startIcon={button.icon}
            >
              {button.text}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};
