import { Interval } from "luxon";

export const intervalIntersection = (intervalSets: Interval[][]): Interval[] => {
  // Check if there are no sets
  if (intervalSets.length < 1) {
    return [];
  }

  // Sort intervals in each set by their start time
  const sortedSets = intervalSets.map((set) =>
    set.sort((a, b) => a.start.valueOf() - b.start.valueOf()),
  );

  // Initialize the result with the first set
  let resultIntervals = sortedSets[0];

  // Iterate through the remaining sets and find the intersection with the result
  for (let i = 1; i < sortedSets.length; i++) {
    const currentSet = sortedSets[i];
    const intersectionIntervals: Interval[] = [];

    let j = 0;
    let k = 0;

    // Compare intervals in the current set with the intervals in the result set
    while (j < resultIntervals.length && k < currentSet.length) {
      const resultInterval = resultIntervals[j];
      const currentInterval = currentSet[k];

      // Check for intersection
      if (resultInterval.overlaps(currentInterval)) {
        const intersection = resultInterval.intersection(currentInterval);
        if (intersection && intersection.isValid) {
          intersectionIntervals.push(intersection);
        }

        // Move to the next interval in the set that ends earlier
        if (resultInterval.end.valueOf() < currentInterval.end.valueOf()) {
          j++;
        } else {
          k++;
        }
      } else if (resultInterval.end.valueOf() < currentInterval.start.valueOf()) {
        // Move to the next interval in the result set
        j++;
      } else {
        // Move to the next interval in the current set
        k++;
      }
    }

    resultIntervals = intersectionIntervals;
  }

  return resultIntervals;
};
