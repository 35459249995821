import { CalendarPositioner } from "@clockwise/web-commons/src/components/calendar";
import { ICalPositionable } from "@clockwise/web-commons/src/components/calendar/calendar-positioner/types";
import { DateTime, Interval } from "luxon";
import React, { useMemo } from "react";
import { useCurrentProposal } from "../../chat-plus-calendar/CurrentProposalContext";
import { MIN_DURATION } from "../planner-event/PlannerEvents";
import { ProposalSlot } from "./ProposalSlot";

export const ProposalTimeOverlay = ({
  dateTimes,
  reserveRail = false,
}: {
  dateTimes: DateTime[];
  reserveRail?: boolean;
}) => {
  const { currentProposal } = useCurrentProposal();
  const primaryCalendarId = currentProposal.eventCalendarId;

  const widthPercent = 100;
  const fade = false;
  const intervals = useMemo(() => {
    if (currentProposal.startTime.hasSame(currentProposal.endTime, "day")) {
      return [Interval.fromDateTimes(currentProposal.startTime, currentProposal.endTime)];
    }
    const retval = [];
    for (
      let i = 0;
      i <= Math.ceil(currentProposal.endTime.diff(currentProposal.startTime, "days").days);
      i++
    ) {
      const curr = currentProposal.startTime.plus({ days: i });
      let startTime = currentProposal.startTime;
      let endTime = currentProposal.endTime;
      if (curr.day !== currentProposal.startTime.day) {
        startTime = curr.set({ hour: 0, minute: 0, second: 0 });
      }
      if (curr.day !== currentProposal.endTime.day) {
        endTime = curr.set({ hour: 23, minute: 59, second: 59 });
      }
      if (startTime.equals(endTime)) {
        continue;
      }
      retval.push(Interval.fromDateTimes(startTime, endTime));
    }
    return retval;
  }, [currentProposal.startTime, currentProposal.endTime]);

  const positionables: ICalPositionable[] = useMemo(
    () =>
      intervals.map((interval) => {
        return {
          key: interval.toISO(),
          interval,
          render: () => (
            <ProposalSlot calendarId={primaryCalendarId} widthPercent={widthPercent} fade={fade} />
          ),
        };
      }),
    [intervals, primaryCalendarId, fade],
  );

  if (!currentProposal.initialized) {
    return null;
  }

  return (
    <div>
      <CalendarPositioner
        dateTimes={dateTimes}
        positionables={positionables}
        gutters={reserveRail}
        minDuration={MIN_DURATION}
      />
    </div>
  );
};
