import { SuperpowersChecklist } from "#webapp/src/components/superpowers-checklist/SuperpowersChecklist";
import { useChecklistFlags } from "#webapp/src/components/superpowers-checklist/useChecklistFlags";
import React from "react";
import { CurrentOrNextEventProvider } from "../../../../hooks/useCurrentOrNextEvent/useCurrentOrNextEvent";
import { useFeatureFlag } from "../../../../launch-darkly/useLaunchDarkly";
import { useAIMessageContext } from "../hooks/AIMessageContext";
import { useCollaborators } from "../hooks/useCollaborators";
import { AINextMeetingLink } from "./AINextMeetingLink";
import { CustomCollaborators } from "./CustomCollaborators";
import { TryClockwiseChecklist } from "./TryClockwiseChecklist";
import { PlanMyWeek } from "./optimize-week/PlanMyWeek";

export const SidebarQuickFeed = () => {
  const [onDemandDefragAvailable] = useFeatureFlag("onDemandDefrag");

  const [onNux2025] = useFeatureFlag("NUX-Q1-2025");
  const { shouldShowSuperpowersChecklist } = useChecklistFlags();
  const { loadingHistory } = useAIMessageContext();
  const {
    topCollaborators,
    customCollaborators,
    loading: collaboratorsLoading,
  } = useCollaborators();

  if (loadingHistory) return null;

  return (
    <CurrentOrNextEventProvider>
      <div className="cw-flex cw-flex-col cw-gap-5 cw-w-full cw-h-full">
        <div className="cw-flex cw-flex-col cw-gap-y-2">
          <AINextMeetingLink />
          {onDemandDefragAvailable && <PlanMyWeek />}
        </div>

        {onNux2025 && shouldShowSuperpowersChecklist && <SuperpowersChecklist />}
        <CustomCollaborators
          loading={collaboratorsLoading}
          topCollaborators={topCollaborators}
          customCollaborators={customCollaborators}
        />
        {!onNux2025 && <TryClockwiseChecklist />}
      </div>
    </CurrentOrNextEventProvider>
  );
};
