import {
  useDefragProposal,
  useUpdateDefragProposal,
} from "#webapp/src/components/chat-plus-calendar/DefragProposalContext";
import { CardWrapper } from "#webapp/src/components/event-card/CardWrapper";
import { Check } from "@clockwise/icons";

import {
  ECHeadTab,
  ECTabGroup,
  ECTabList,
  ECTabPanel,
  ECTabPanels,
} from "#webapp/src/components/event-card/molecules/ECTabs";
import { ConfirmProposalDocument } from "#webapp/src/components/reschedule-confirmation-modal/__generated__/ConfirmProposal.v2.generated";
import { eventToast } from "#webapp/src/components/web-app-calendar/notifiation-event/EventToast";
import { Button } from "@clockwise/design-system";
import { ProposalAppliesTo } from "@clockwise/schema/v2";
import { useGatewayMutation } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import { track, TrackingEvents } from "@clockwise/web-commons/src/util/analytics.util";
import React from "react";
import { useAIMessageContext } from "../../hooks/AIMessageContext";
import { OnDemandDefrag } from "./OnDemandDefrag";

export const PlanYourWeekWrapper = () => {
  const { onDemandDefrag } = useDefragProposal();
  const { setProcessingMessage } = useAIMessageContext();
  const { cancelOnDemandDefragRequest } = useUpdateDefragProposal();
  const proposal = onDemandDefrag.proposal;

  const [confirmProposal, { loading: confirmingProposal }] = useGatewayMutation(
    ConfirmProposalDocument,
    {
      refetchQueries: ["CalendarEventsQuery"],
      awaitRefetchQueries: true,
    },
  );

  const handleSaveAndFix = async () => {
    if (!proposal?.id) return;
    setProcessingMessage(false);
    try {
      track(TrackingEvents.CHAT.ODD.ODD_ACCEPTED, { proposalId: proposal.id });
      const result = await confirmProposal({
        variables: {
          proposalId: proposal.id,
          appliesTo: ProposalAppliesTo.Instance,
        },
      });
      if (result.data?.confirmProposal) {
        eventToast.success({ title: "Event moved succesfully", operation: "RESCHEDULE" });
        cancelOnDemandDefragRequest();
      } else {
        throw new Error("Failed to confirm proposal");
      }
    } catch (error) {
      console.log(`Failed to confirm proposal: ${error.message}`, error);
    }
  };

  if (!proposal) return null; // Shouldn't hit this, used in type safety

  return (
    <ECTabGroup defaultIndex={0}>
      <CardWrapper
        onClose={cancelOnDemandDefragRequest}
        header={
          <>
            <ECTabList>
              <ECHeadTab>Event</ECHeadTab>
              <ECHeadTab>History</ECHeadTab>
            </ECTabList>
          </>
        }
        footer={
          <div className="cw-flex cw-p-3 cw-justify-between cw-relative">
            <Button
              disabled={confirmingProposal}
              size="xsmall"
              sentiment="positive"
              onClick={handleSaveAndFix}
              startIcon={Check}
            >
              Confirm
            </Button>
            <Button
              disabled={confirmingProposal}
              size="xsmall"
              variant="outlined"
              onClick={() => {
                track(TrackingEvents.CHAT.ODD.ODD_CANCELED, { proposalId: proposal?.id });
                setProcessingMessage(false);
                cancelOnDemandDefragRequest();
              }}
            >
              Cancel
            </Button>
          </div>
        }
      >
        <ECTabPanels>
          <ECTabPanel className="cw-px-4 cw-py-6 cw-body-base">
            <OnDemandDefrag proposal={onDemandDefrag.proposal} />
          </ECTabPanel>
          <ECTabPanel className="cw-px-4 cw-py-6 cw-body-base">I AM HISTORY</ECTabPanel>
        </ECTabPanels>
      </CardWrapper>
    </ECTabGroup>
  );
};
