import { useQuery } from "@apollo/client";
import { getValue } from "@clockwise/client-commons/src/util/errorable.util";
import { useGatewayQuery } from "@clockwise/web-commons/src/network/apollo/gateway-provider";
import React from "react";
import { useFeatureFlag } from "../../launch-darkly";
import { getCurrentOrg } from "../../util/org.util";
import { WebSettingsEmailsAndNotifications } from "../web-settings/web-settings-emails-and-notifications";
import { WebSettingsHoursAndPreferencesRelay } from "../web-settings/web-settings-hours-and-preferences";
import { AccountDetails } from "./AccountDetails";
import { AccountSettingsSkeleton } from "./AccountSettingsSkeleton";
import { PreferredVideoConferencing } from "./PreferredVideoConferencing";
import { AccountSettingsDocument } from "./__generated__/AccountSettings.generated";
import { UserSettingsDocument } from "./__generated__/UserSettings.v2.generated";

const PREFERENCES_NAV_ITEM_LABEL = "Preferences";

const AccountSettings = () => {
  const { data, loading } = useQuery(AccountSettingsDocument);
  const { data: userSettingsData, loading: userSettingsLoading } = useGatewayQuery(
    UserSettingsDocument,
  );
  const [isOnPreferredVideoConference] = useFeatureFlag("PreferredVideoConference");
  const viewer = data?.viewer;
  const org = getCurrentOrg(viewer);

  const email = getValue(org?.userPerson, "Person")?.primaryEmail ?? null;
  const preferredVC = userSettingsData?.userSettings?.preferredVideoConference;

  if (loading) {
    return <AccountSettingsSkeleton />;
  }

  return (
    <div className="cw-min-w-[320px] cw-w-full cw-max-w-screen-lg">
      {org && (
        <WebSettingsHoursAndPreferencesRelay
          title={PREFERENCES_NAV_ITEM_LABEL}
          orgRelayId={org?.id}
        />
      )}
      {isOnPreferredVideoConference && preferredVC && (
        <PreferredVideoConferencing value={preferredVC} loading={userSettingsLoading} />
      )}
      <WebSettingsEmailsAndNotifications />
      <div className="cw-py-10 cw-px-[60px]">
        <h1 className="cw-heading-3xl cw-m-0 cw-mb-11">Account Details</h1>
        {email && <AccountDetails email={email} />}
      </div>
    </div>
  );
};

export default AccountSettings;
