import { OnboardingFlowState } from "../useOnboarding/useOnboarding";

export enum NUXChecklistState {
  Unstarted = "Unstarted",
  Skipped = "Skipped",
  Finished = "Finished",
}

const NUXChecklistStepList: OnboardingFlowState[] = [
  //order matters
  OnboardingFlowState.ChecklistFlexMeetings,
  OnboardingFlowState.ChecklistPreferences,
  OnboardingFlowState.ChecklistHolds,
];

const findFirstStepForState = (
  flowStateMap: { [key: string]: NUXChecklistState },
  state: NUXChecklistState,
) => {
  let currentStep: OnboardingFlowState | null = null;
  for (const step of NUXChecklistStepList) {
    if (flowStateMap[step] === state) {
      currentStep = step;
      break;
    }
  }
  return currentStep;
};

export const getCurrentActiveStep = (flowStateMap: { [key: string]: NUXChecklistState }) => {
  const firstUnstarted = findFirstStepForState(flowStateMap, NUXChecklistState.Unstarted);
  const firstSkipped = findFirstStepForState(flowStateMap, NUXChecklistState.Skipped);

  return !firstUnstarted ? firstSkipped : firstUnstarted;
};
