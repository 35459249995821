import { paths } from "@clockwise/client-commons/src/constants/site";
import {
  appPaths,
  pageSlugs,
  webappPathRoot,
} from "@clockwise/web-commons/src/constants/route.constants";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { GoogleAuthRedirect } from "../auth/GoogleAuthRedirect";
import { MicrosoftAuthRedirect } from "../auth/MicrosoftAuthRedirect";
import { AppAuthenticationLayout } from "../components/app-layout/AppAuthenticationLayout";
import { AppLayout } from "../components/app-layout/AppLayout";
import { AppLogout } from "../components/app-layout/AppLogout";
import { AppPublicLayout } from "../components/app-layout/AppPublicLayout";
import { AppRestrictedInternalLayout } from "../components/app-layout/AppRestrictedInternalLayout";
import { AppRestrictedSudoLayout } from "../components/app-layout/AppRestrictedSudoLayout";
import { ChromeZoomVerifierPage } from "../components/chrome-zoom-verifier";
import { HomeUninstall } from "../components/home-uninstall";
import { LoginWrapper } from "../components/login-wrapper";
import { NoRouteView } from "../components/no-route-view";
import { NonUserDefragSurvey } from "../components/non-user-defrag-survey/NonUserDefragSurvey";
import { SudoProvisioningPage } from "../components/sudo-provisioning/SudoProvisioningPage";
import { SudoWrapperPage } from "../components/sudo-wrapper/SudoWrapper";
import { NewTeamPage } from "../components/team-settings/NewTeamPage";
import TeamCreateOrJoinPage from "../components/team-settings/TeamCreateOrJoinPage";
import { UnsubscribeWrapperPage } from "../components/unsubscribe-wrapper";
import { WebAppCheckoutPage } from "../components/web-app-checkout";
import { WebAppPricingPage } from "../components/web-app-pricing";
import { WebAppSlack } from "../components/web-app-slack";
import { WebOnboarding } from "../components/web-app/WebOnboarding";
import { WebSettingsExperiments } from "../components/web-settings/web-settings-experiments";
import AIPage from "../pages/AIPage";
import AccountPreferencesPage from "../pages/AccountPreferencesPage";
import AccountSettingsPage from "../pages/AccountSettingsPage";
import AdminAnalyticsPage from "../pages/AdminAnalyticsPage";
import AppSettingsPage from "../pages/AppSettingsPage";
import { AutomationPage } from "../pages/AutomationPage";
import FlexibleMeetingsPage from "../pages/FlexibleMeetingsPage";
import { GatewayGraphiQLPage } from "../pages/GatewayGraphiQLPage";
import { InstallExtensionPage } from "../pages/InstallExtensionPage";
import { LinkBookingPage } from "../pages/LinkBookingPage";
import { LinkCancelPage } from "../pages/LinkCancelPage";
import { LinkReschedulePage } from "../pages/LinkReschedulePage";
import OrganizationMembersPage from "../pages/OrganizationMembersPage";
import OrganizationSettingsPage from "../pages/OrganizationSettingsPage";
import PlansAndBillingPage from "../pages/PlansAndBillingPage";
import ProposalPage from "../pages/ProposalPage";
import { PublicSharedProposalPage } from "../pages/PublicSharedProposalPage";
import SchedulingLinksPage from "../pages/SchedulingLinksPage";
import { SetupSchedulingLinkPage } from "../pages/SetupSchedulingLinkPage";
import TeamsSettingPage from "../pages/TeamsSettingsPage";
import { ChromeSlackRouter } from "./ChromeSlackRouter";
import { InviteAttributionAndRedirect } from "./InviteAttributionAndRedirect";
import { LoggedNavigate } from "./LoggedNavigate";

export const AppRouter = () => {
  return (
    <>
      <Routes>
        {/* Authenticated Routes */}
        <Route path={webappPathRoot} element={<AppAuthenticationLayout />}>
          {/* Legacy Redirects */}
          <Route path="admin" element={<LoggedNavigate to={appPaths.analytics} />} />
          <Route path="ai" element={<LoggedNavigate to={appPaths.ai} />} />
          <Route path="ai-scheduler" element={<LoggedNavigate to={appPaths.ai} />} />
          <Route path="event/*" element={<LoggedNavigate to={appPaths.ai} />} />
          <Route path="flexible-holds" element={<Navigate to={appPaths.flexibleHolds} />} />
          <Route path="flexible-meetings" element={<Navigate to={appPaths.flexibleMeetings} />} />
          <Route path="planner/*" element={<LoggedNavigate to={appPaths.ai} />} />
          <Route path="one-on-ones" element={<LoggedNavigate to={appPaths.flexibleHolds} />} />
          <Route path="web-settings/*" element={<LoggedNavigate to={appPaths.preferences} />} />

          {/* No App Layout */}
          <Route path={pageSlugs.onboarding} element={<WebOnboarding />} />
          <Route path={`${pageSlugs.pricing}/:teamId?`} element={<WebAppPricingPage />} />

          {/* App Layout */}
          <Route element={<AppLayout />}>
            <Route index element={<AIPage />} />
            <Route path={pageSlugs.accountPreferences} element={<AccountPreferencesPage />} />
            <Route path={pageSlugs.analytics} element={<AdminAnalyticsPage />} />
            <Route path={pageSlugs.appSettings} element={<AppSettingsPage />} />
            <Route path={pageSlugs.createOrJoinTeam} element={<TeamCreateOrJoinPage />} />
            <Route
              path={pageSlugs.flexibleHolds}
              element={<Navigate to={`${appPaths.flexibleMeetings}#holds`} />}
            />
            <Route path={pageSlugs.flexibleMeetings} element={<FlexibleMeetingsPage />} />
            <Route path={pageSlugs.graphiql} element={<GatewayGraphiQLPage />} />
            <Route path={pageSlugs.landing} element={<Navigate to={appPaths.ai} />} />
            <Route path={pageSlugs.orgSettings} element={<OrganizationSettingsPage />} />
            <Route path={pageSlugs.plansAndBilling} element={<PlansAndBillingPage />} />
            <Route path={pageSlugs.preferences} element={<AccountSettingsPage />} />
            <Route path={pageSlugs.teamsSettings} element={<TeamsSettingPage />} />
            <Route path={pageSlugs.createSchedulingLink} element={<SetupSchedulingLinkPage />} />
            <Route path={pageSlugs.editSchedulingLink} element={<SetupSchedulingLinkPage />} />
            <Route path={`${pageSlugs.checkout}/:teamId?`} element={<WebAppCheckoutPage />} />
            <Route path={`${pageSlugs.teamSettings}/:teamId`} element={<NewTeamPage />} />
            <Route path={`${pageSlugs.proposal}/:proposalId`} element={<ProposalPage />} />
            <Route path={pageSlugs.schedulingLinks} element={<SchedulingLinksPage />} />
            <Route
              path={`${pageSlugs.members}/:billingGroupId?`}
              element={<OrganizationMembersPage />}
            />

            {/* Restricted to internal users */}
            <Route element={<AppRestrictedInternalLayout />}>
              <Route path={pageSlugs.experiments} element={<WebSettingsExperiments />} />
              <Route path={pageSlugs.graphiql} element={<GatewayGraphiQLPage />} />
            </Route>

            {/* Restricted to sudo users */}
            <Route element={<AppRestrictedSudoLayout />}>
              <Route path={pageSlugs.sudo} element={<SudoWrapperPage />} />
              <Route path={pageSlugs.provisioning} element={<SudoProvisioningPage />} />
            </Route>

            <Route path="*" element={<NoRouteView />} />
          </Route>
        </Route>

        {/* Public Routes - Wrapped */}
        <Route element={<AppPublicLayout />}>
          <Route path={paths.uninstall} element={<HomeUninstall />} />
          <Route path={`${paths.unsubscribe}/:email/:token`} element={<UnsubscribeWrapperPage />} />
          <Route
            path={`${paths.nonUserDefragSurvey}/:emailInstanceId?`}
            element={<NonUserDefragSurvey />}
          />

          {/* Logging Out */}
          <Route path="logout" element={<AppLogout />} />

          <Route path={`${paths.login}/:redirect?`} element={<LoginWrapper variant="default" />} />
          <Route path={`${paths.signup}/:redirect?`} element={<LoginWrapper variant="invite" />} />
          <Route
            path={`${paths.welcome}/:redirect?`}
            element={<LoginWrapper variant="welcome" />}
          />
          <Route
            path={`${paths.m365SignIn}/:redirect?`}
            element={<LoginWrapper variant="m365" />}
          />

          {/* Slack */}
          <Route path={`${paths.slackLanding}/:step?`} element={<WebAppSlack />} />
        </Route>

        {/* Public Routes */}
        <Route path={paths.chromeSlackVerifier} element={<ChromeSlackRouter />} />
        <Route path={`${paths.chromeSlackVerifier}/team/:teamId`} element={<ChromeSlackRouter />} />
        <Route path={paths.schedulingLink} element={<LinkBookingPage />} />
        <Route path={paths.cancelBooking} element={<LinkCancelPage />} />
        <Route path={paths.rescheduleBooking} element={<LinkReschedulePage />} />
        <Route path={paths.googleAuthRedirect} element={<GoogleAuthRedirect />} />
        <Route path={paths.installExtension} element={<InstallExtensionPage />} />
        <Route path={paths.microsoftAuthRedirect} element={<MicrosoftAuthRedirect />} />
        <Route path={paths.sharedProposal} element={<PublicSharedProposalPage />} />
        <Route path={`${paths.chromeZoomVerifier}/account`} element={<ChromeZoomVerifierPage />} />
        <Route path={`${paths.chromeZoomVerifier}/user`} element={<ChromeZoomVerifierPage />} />
        <Route
          path={`${paths.personalInvite}/:inviteToken?`}
          element={<InviteAttributionAndRedirect redirect={paths.welcome} />}
        />

        <Route path={`${webappPathRoot}/automation`} element={<AutomationPage />} />

        {/* Public Routes - 404 */}
        <Route path="*" element={<NoRouteView />} />
      </Routes>
    </>
  );
};
